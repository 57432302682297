import * as React from 'react'
import {
  Box,
  GridList,
  GridListTile,
  isWidthUp,
  makeStyles,
  withWidth,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core'
import {graphql} from 'gatsby'
import Logo from '../components/logo'
import Link from 'gatsby-link'
import Img from 'gatsby-image'
import hexSorter from 'hexsorter'
import {brightnessByColor} from "../utils/brightnessByColor";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    overflowY: 'hidden',
  },
  gridListTile: {
  },
  gridListImage: {
    borderRadius: '5px',
    // apply basic .MuiGridListTile-imgFullHeight style at load so images don't shift
    left: '50%',
    height: '100%',
    position: 'relative',
    transform: 'translateX(-50%)',
    '&:hover': {
      opacity: 0.65
    }
  },
}))

const MaterialRecordsPage = ({width, data}) => {
  const getCoverDimensions = () => {
    if (isWidthUp('sm', width)) { return 2 }
    return 1
  }

  const classes = useStyles()
  const imgWidth = 390
  const columns = getCoverDimensions()

  return (
    <main>
      <title>internet turntable.</title>
      <Box px={2} pt={1} mx={'auto'} style={{maxWidth: '800px'}}>
        <Logo/>
        <GridList
          className={classes.gridList}
          cols={columns}
          cellHeight={imgWidth}
        >
          {data.allAirtable.edges.map((edge, index) => {
            const record = edge.node.data
            const image = data.allImageSharp.edges.find(edge => edge.node.fixed.originalName === `${record.Discogs_ID}.jpg`)
            const mostSaturatedColor = hexSorter.sortColors(record.Colors, 'mostSaturatedColor')[0]

            const RecordTooltip = withStyles((theme) => ({
              arrow: {
                color: mostSaturatedColor,
              },
              tooltip: {
                color: brightnessByColor(mostSaturatedColor) < 128 ? '#FFFFFF' : '#333333',
                backgroundColor: mostSaturatedColor,
                maxWidth: 'none',
              }
            }))(Tooltip);

            return (
              <RecordTooltip
                arrow
                placement={'top'}
                title={
                  <div style={{textAlign: 'center'}}>
                    <Typography variant={'subtitle1'}>{record.Name}</Typography>
                    <Typography variant={'subtitle2'}>{record.Artist}</Typography>
                  </div>
                }
                style={{color: "#000000"}}
              >
                <Link to={`/record/${record.Discogs_ID}`}>
                  <GridListTile
                    key={record.Cover_Thumbnail}
                    cols={1}
                    className={classes.gridListTile}
                    alignItems={'center'}
                    container
                  >
                    <Img
                      fixed={image.node.fixed}
                      alt={`${record.Name} - ${record.Artist} Record Cover`}
                      className={classes.gridListImage}
                    />
                  </GridListTile>
                </Link>
              </RecordTooltip>
            )
          })}
        </GridList>
      </Box>
    </main>
  )
}

export default withWidth()(MaterialRecordsPage)

export const query = graphql`
  query {
    allAirtable(
      sort: { fields: [data___Artist_Sort, data___Released, data___Name] }
    ) {
      edges {
        node {
          data {
            Added
            Track_List
            Styles
            Released
            Name
            Formats
            Genres
            Discogs_URL
            Discogs_ID
            Colors
            Artist
          }
        }
      }
    }
    allImageSharp {
      edges {
        node {
          fixed(width: 390, height: 390) {
            originalName
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  }
`
