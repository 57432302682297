import * as React from 'react'
import ReactMarkdown from 'react-markdown'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory } from '@fortawesome/free-solid-svg-icons'
import ReactTooltip from 'react-tooltip'
import hexSorter from 'hexsorter'
import { brightnessByColor } from '../utils/brightnessByColor'
import { graphql } from 'gatsby'
import Logo from "../components/logo"

// styles
const pageStyles = {
  color: '#232129',
  padding: '48px 96px 16px 96px',
  fontFamily: '-apple-system, Roboto, sans-serif, serif',
}

const descriptionStyle = {
  color: '#232129',
  fontSize: '14px',
  whiteSpace: 'pre-line',
}

const listStyles = {
  marginBottom: 16,
  paddingLeft: 0,
}

const listItemStyles = {
  fontWeight: '300',
  fontSize: '24px',
  maxWidth: '1400px',
  listStyle: 'none',
  marginBottom: '64px',
  display: 'flex',
  flexFlow: 'row nowrap'
}

const coverImageStyle = {
  maxWidth: '600px',
  maxHeight: '600px',
  borderRadius: '10px',
  marginRight: '4px',
}

const linkStyle = {
  color: '#8954A8',
  fontWeight: 'bold',
  fontSize: '16px',
  verticalAlign: '5%',
}

const albumLinkStyle = {
  ...linkStyle,
}

const formatStyle = {
  fontSize: '14px',
  color: 'white',
  marginRight: '6px',
  padding: '5px',
  borderRadius: '6px',
  fontWeight: '500',
}

const genreStyle = {
  ...descriptionStyle,
  fontStyle: 'italic',
}

const trackListStyle = {
  ...descriptionStyle,
  lineHeight: '1.2rem',
}

const trackPositionStyle = {
  fontWeight: 'bold',
}

const trackTitleStyle = {
}

const colorSwatchStyle = {
  width: '16px',
  height: '16px',
  margin: '2px',
  borderRadius: '4px'
}

const colorSwatchesStyle = {
  display: 'flex',
  flexFlow: 'row nowrap',
  marginTop: '8px',
}

const recordInfoStyle = {
  maxWidth: '600px',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'flex-start'
}

const otherImagesStyle = {
  minWidth: '320px',
  maxWidth: '500px',
  maxHeight: '1000px',
  display: 'flex',
  flexFlow: 'column wrap',
  justifyContent: 'spaceBetween',
}

const otherImageStyle = {
  maxWidth: '150px',
  maxHeight: '150px',
  borderRadius: '5px',
  marginLeft: '5px',
  marginRight: '5px',
  marginBottom: '5px',
}

// markup
const RecordPage = ({data}) => {
  return (
    <main style={pageStyles}>
      <title>internet turntable.</title>
      <Logo/>
      <ul style={listStyles}>
        {data.allAirtable.edges.map(edge => {
          const record = edge.node.data
          const mostSaturatedColor = hexSorter.sortColors(record.Colors, 'mostSaturatedColor')[1]
          return (
            <li style={{ ...listItemStyles, color: mostSaturatedColor }}>
              <div style={recordInfoStyle}>
                <img
                  alt={`${record.Name} - ${record.Artist} Record Cover`}
                  src={record.Cover}
                  style={coverImageStyle}
                />
                <div>
                  <div>
                    <a
                      style={albumLinkStyle}
                      href={record.Discogs_URL}
                    >
                      {record.Name} - {record.Artist} ({record.Released})
                    </a>{' '}
                    <FontAwesomeIcon icon={faHistory} size="xs" data-tip={'Last played ?'}/>
                  </div>
                  <div>
                    {record.Formats.split(';').map(format => (
                      <span style={{
                        ...formatStyle,
                        backgroundColor: mostSaturatedColor,
                        color: brightnessByColor(mostSaturatedColor) < 128 ? '#FFFFFF' : '#333333',
                      }}>
                        <ReactMarkdown
                          disallowedTypes={['paragraph']}
                          unwrapDisallowed
                        >
                          {format.replace('Vinyl, ', '')}
                        </ReactMarkdown>
                      </span>
                    ))}
                  </div>
                  <div style={colorSwatchesStyle}>
                    {hexSorter.sortColors(record.Colors, 'mostBrightColor').reverse().map(color => (
                      <div style={{...colorSwatchStyle, backgroundColor: color}}/>
                    ))}
                  </div>
                  <p style={genreStyle}>
                    {record.Genres.slice(0,3).join(', ')}
                    {record.Styles && ` | ${record.Styles.slice(0,3).join(', ')}`}
                  </p>
                  <p style={trackListStyle}>
                    {record.Track_List && JSON.parse(record.Track_List).map(track => {
                      return (
                        <div>
                          <span style={trackPositionStyle}>{track.position}</span>{' '}
                          <span style={trackTitleStyle}>{track.title}</span>
                        </div>
                      )
                    })}
                  </p>
                </div>
              </div>
              <div style={otherImagesStyle}>
                {record.Other_Images_Thumbnails && record.Other_Images_Thumbnails.split(' ').map(image => {
                  return (
                    <img
                      alt={`${record.Name} - ${record.Artist}, Additional Media`}
                      src={image}
                      style={otherImageStyle}
                      width={150}
                      height={150}
                    />
                  )
                })}
              </div>
            </li>
          )
        })}
      </ul>
      <ReactTooltip
        effect={'solid'}
      />
    </main>
  )
}

export default RecordPage

export const query = graphql`
query($slug: String!) {
  allAirtable(
    filter: {
      data: { Discogs_ID: { eq: $slug } }
    }
  ) {
    edges {
      node {
        data {
          Added
          Track_List
          Styles
          Released
          Other_Images_Thumbnails
          Name
          Formats
          Genres
          Discogs_URL
          Discogs_ID
          Cover
          Colors
          Artist
        }
      }
    }
  }
}
`
